import React, { useEffect, useState } from "react";
import Markdown from "markdown-to-jsx";

import { AboutStyles, VideoContainer } from "./About.styled";
import Skeleton from "./Skeleton/Skeleton";
const About = ({ about }) => {
  return (
    <AboutStyles className="section" id="about">
      <div className="container">
        <h2>{about?.title.es}</h2>
        <div className="introduction">
          <Markdown>{about?.description.es}</Markdown>
        </div>
        <div className="video">
          <VideoContainer>
            {/* <Skeleton /> */}
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/AcLUl_kCMC0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </VideoContainer>
        </div>
      </div>
    </AboutStyles>
  );
};

export default About;
