import React from "react";

import { StatementStyles, StatementsStyles, Icon } from "./Highlights.styled";

const Statement = ({ title, text, icon }) => (
  <StatementStyles>
    <Icon imageUrl={icon} />
    <h4>{title}</h4>
    <p>{text}</p>
  </StatementStyles>
);

export default function Highlights({ highlights }) {
  return (
    <StatementsStyles className="section">
      <ul className="container">
        {highlights.map((highlight, i) => (
          <Statement
            key={i}
            icon={highlight.icon.asset.url}
            title={highlight.title.es}
            text={highlight.description.es}
          />
        ))}
      </ul>
    </StatementsStyles>
  );
}
