import React from "react";

import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Header from "../components/Header/Header";
import About from "../components/About/About";
import Highlights from "../components/Highlights";
import Services from "../components/Services";
import Contact from "../components/Contact";
import SEO from "../components/SEO";

const IndexPage = ({ data }) => {
  const highlights = data.highlights.nodes;
  const about = data.about.nodes[0];
  const services = data.services.nodes;

  return (
    <>
      <SEO />
      <About about={about} />
      <Highlights highlights={highlights} />
      <Services services={services} />
      <Contact />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    about: allSanityAbout {
      nodes {
        title {
          es
        }
        description {
          es
        }
        link
      }
    }
    highlights: allSanityHighlight {
      nodes {
        icon {
          asset {
            url
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
        title {
          es
        }
        description {
          es
        }
      }
    }
    services: allSanityService(sort: { fields: order, order: ASC }) {
      nodes {
        order
        image {
          asset {
            id
            url
            metadata {
              dimensions {
                height
                width
              }
            }
            fluid(maxWidth: 1200) {
              ...GatsbySanityImageFluid
            }
          }
          hotspot {
            y
            x
          }
          crop {
            bottom
            left
            right
            top
          }
        }
        title {
          es
        }
        subtitle {
          es
        }
        subservices {
          title {
            es
          }
          description {
            es
          }
        }
        slug {
          current
        }
      }
    }
  }
`;
