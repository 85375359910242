import styled, { css } from "styled-components";
import { mediaQuery } from "../../styles/mixins";

export const Icon = styled.span`
  display: block;
  height: 60px;
  width: 60px;
  margin: auto;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
`;

export const StatementsStyles = styled.section`
  font-family: "Inter", sans-serif;
  background-color: #f0ebe6;
  background-color: #f0f3fb69;
  /* background-color: var(--color__secondary-light); */
  /* background-color: var(--color__primary-alt); */

  /* padding: 60px; */
  padding-top: 90px;
  padding-bottom: 140px;
  ul {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.3rem;
    ${mediaQuery.sm`
          grid-template-columns: 1fr 1fr;
  `}
    ${mediaQuery.lg`
          grid-template-columns: 1fr 1fr 1fr;
  `}
  }
`;

export const StatementStyles = styled.li`
  /* background-color: var(--midGrey); */
  padding: 1rem 1.2rem;
  display: block;

  padding: 1.5rem;
  /* border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.05); */
  border-radius: 8px;
  text-align: center;

  h4 {
    font-size: 1.2rem;
    color: navy;
    font-weight: 600;
    font-size: 24px;
    color: #303030;
    line-height: 120%;
    margin-bottom: 32px;
    margin-top: 48px;
  }
  p {
    font-size: 1rem;
    font-size: 16px;
    line-height: 160%;
    color: #303030;
  }

  margin: 0 auto;
  max-width: 500px;
`;
